import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

const PotentialRegistrarActions = () => {
	const intl = useIntl();
	return (
		<Layout
			view='builder'
			breadcrumb={{
				text: <FormattedMessage id='builderNav.dashboard' />,
				path: '/builder-vendor/dashboard/'
			}}
			header={<FormattedMessage id='adminPenalties.header' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'adminPenalties.header' })} />
			<Container>
				<Row>
					<Col lg={3} md={3}>
						<ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/administrative-penalty/' />
					</Col>

					<Col>
						<Row>
							<Col>
								<p style={{ marginTop: `0` }}>
									<FormattedMessage id='adminPenalties.contentA' />
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentB' />
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentC' />
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentD' />
									<ul>
										<li>
											<FormattedMessage id='adminPenalties.listAi' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listAii' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listAiii' />{' '}
											<a
												href={`../../../../governance/${intl.formatMessage({
													id: 'linkNames.ampLink'
												})}`}
											>
												<FormattedMessage id='adminPenalties.listAiv' />
											</a>{' '}
											<FormattedMessage id='adminPenalties.listAv' />
										</li>
									</ul>
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentE' />
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentF' />
									<ul>
										<li>
											<FormattedMessage id='adminPenalties.listBi' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listBii' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listBiii' />
										</li>
									</ul>
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentG' />
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentH' />
									<ul>
										<li>
											<FormattedMessage id='adminPenalties.listCi' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listCii' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listCiii' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listCiv' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listCv' />
										</li>
										<li>
											<FormattedMessage id='adminPenalties.listCvi' />
										</li>
									</ul>
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentIi' />{' '}
									<Link to='/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'>
										<FormattedMessage id='adminPenalties.contentIii' />
									</Link>
									.
								</p>
								<p>
									<FormattedMessage id='adminPenalties.contentJi' />{' '}
									<a href='https://www.ontario.ca/laws/regulation/220573'>
										<FormattedMessage id='adminPenalties.contentJii' />
									</a>
									<FormattedMessage id='adminPenalties.contentJiii' />
								</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='builderComplaints4.headerAalt' />,
								path: '/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions/'
							}}
							nextLink={{
								text: <FormattedMessage id='builderNav.complaints2' />,
								path: '/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default PotentialRegistrarActions;
